<template>
	<div>
		<Fonts
			:font-types="[currentFontType]"
			:label="$t('common.font')"
			@font-change="setDefaultTypographyStyles($event)"
		/>
		<ZyroCollapse
			v-for="[element, { title }] in Object.entries(typographyCategoryElements)"
			:key="element"
			size="x-large"
			has-seperator
			class="collapse"
			:is-open="selectedTypographyElement === element"
			@toggle="toggleElementEdit(element)"
		>
			<template #trigger>
				<h2 class="z-body-small z-body-small--strong">
					{{ title }}
				</h2>
			</template>
			<div>
				<TypographyControls
					:element="element"
					:font-type="currentFontType"
					class="controls"
				/>
				<TextBoxPreview :element="element" />
			</div>
		</ZyroCollapse>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
	mapState,
} from 'vuex';

import Fonts from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/typography/Fonts.vue';
import { DEFAULT_TEXT_STYLES } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/constants';
import TypographyControls
	from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/controls/TypographyControls.vue';
import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import TextBoxPreview from '@/components/builder-drawers/drawers/partials/stylesMisc/TextBoxPreview.vue';
import {
	PROPERTY_FONT_PRIMARY,
	TYPOGRAPHY_BODY,
	TYPOGRAPHY_BODY_LARGE,
	TYPOGRAPHY_BODY_SMALL,
	TYPOGRAPHY_H1,
	TYPOGRAPHY_H2,
	TYPOGRAPHY_H3,
	TYPOGRAPHY_H6,
	TYPOGRAPHY_H4,
	TYPOGRAPHY_H5,
	PROPERTY_FONT_FAMILY,
} from '@/constants/globalStyles';
import i18n from '@/i18n/setup';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import {
	CHANGE_DRAWER_OPTIONS,
	mapActionsGui,
} from '@/store/builder/gui';
import {
	extractFontName,
	transformFontTypeToVariable,
} from '@/utils/font';

const HEADINGS = {
	[TYPOGRAPHY_H1]: { title: `${i18n.t('common.heading')} 1` },
	[TYPOGRAPHY_H2]: { title: `${i18n.t('common.heading')} 2` },
	[TYPOGRAPHY_H3]: { title: `${i18n.t('common.heading')} 3` },
	[TYPOGRAPHY_H4]: { title: `${i18n.t('common.heading')} 4` },
	[TYPOGRAPHY_H5]: { title: `${i18n.t('common.heading')} 5` },
	[TYPOGRAPHY_H6]: { title: `${i18n.t('common.heading')} 6` },
};

const PARAGRAPHS = {
	[TYPOGRAPHY_BODY_LARGE]: { title: `${i18n.t('common.paragraph')} 1` },
	[TYPOGRAPHY_BODY]: { title: `${i18n.t('common.paragraph')} 2` },
	[TYPOGRAPHY_BODY_SMALL]: { title: `${i18n.t('common.paragraph')} 3` },
};

export default {
	components: {
		Fonts,
		TextBoxPreview,
		TypographyControls,
	},
	setup() {
		const { currentTypographyStyleLibrary } = useTypographyStylesLibrary();

		return {
			currentTypographyStyleLibrary,
			HEADINGS,
			USER_STYLES_DRAWER,
		};
	},
	data() {
		return { currentFont: '' };
	},
	computed: {
		...mapGetters('fonts', ['getFontNames']),
		...mapState('gui', ['drawerPage']),
		userStyleDrawer: ({ drawerPage }) => drawerPage[USER_STYLES_DRAWER],
		currentFontType: ({ userStyleDrawer }) => userStyleDrawer.fontType,
		currentFontByType: ({
			getFontNames,
			currentFontType,
		}) => getFontNames[currentFontType],
		selectedTypographyElement: ({ userStyleDrawer }) => userStyleDrawer.options.currentTypographyType,
		typographyCategoryElements: ({ currentFontType }) => (
			currentFontType === PROPERTY_FONT_PRIMARY
				? HEADINGS
				: PARAGRAPHS
		),
	},
	mounted() {
		this.setDefaultFontTypes();
		this.currentFont = this.currentFontByType;
	},
	methods: {
		...mapActionsGui({ changeDrawerOptions: CHANGE_DRAWER_OPTIONS }),
		...mapMutations([
			'setStyleProperties',
			'setStyleProperty',
		]),
		setDefaultTypographyStyles() {
			const currentTypographyLibraryFont = this.currentTypographyStyleLibrary.textElementData.font[this.currentFontType];

			if (this.currentFont === extractFontName(currentTypographyLibraryFont)) {
				Object.keys(this.typographyCategoryElements).forEach((element) => {
					this.setStyleProperties({
						element,
						value: DEFAULT_TEXT_STYLES[element],
					});
				});

				this.currentFont = this.currentFontByType;
			}
		},
		setDefaultFontTypes() {
			Object.keys(this.typographyCategoryElements).forEach((element) => this.setStyleProperty({
				element,
				property: PROPERTY_FONT_FAMILY,
				value: transformFontTypeToVariable(this.drawerPage[USER_STYLES_DRAWER].fontType),
			}));
		},
		toggleElementEdit(element) {
			this.changeDrawerOptions({
				drawerKey: USER_STYLES_DRAWER,
				options: { currentTypographyType: element },
			});
		},
	},
};
</script>

<style scoped lang="scss">
.controls {
	margin-bottom: 16px;
}

.collapse {
	border-bottom: 1px solid $grey-400;
}
</style>
